import {makeObservable, computed, action} from 'mobx'
import {extendObservable} from './mobx-ko-proxy'
import Urls from 'urls'
import {Repository} from 'view-models/data/repository'

export class Distributor {
    constructor (id, data) {
        this.id = id
        makeObservable(this, {
            customerSupportContacts: computed,
            vssSupportContacts: computed,
            vssBillingContacts: computed,
            salesContacts: computed,
            computedVssBillingContacts: computed,
            widgetContacts: computed,
            _load: action
        })

        extendObservable(this, {
            name: null,
            compositeIdentifier: null,
            countryCode: null,
            isAuthorized: false,
            learnMoreLinks: null,
            contacts: [],
            sections: []
        })

        this._load(data)
    }

    get customerSupportContacts () {
        return this.getContact('customer_support')
    }

    get vssSupportContacts () {
        return this.getSectionContact('service_select_priority_support')
    }

    get vssBillingContacts () {
        return this.getContact('vss_billing') || {}
    }

    get salesContacts () {
        return this.getSectionContact('need_help')
    }

    get computedVssBillingContacts () {
        const contacts = [this.vssBillingContacts, this.vssSupportContacts, this.customerSupportContacts]
        return {
            phone: this.findContactItem('phone', contacts),
            email: this.findContactItem('email', contacts)
        }
    }

    get widgetContacts () {
        return this.getSectionContact('widget_support')
    }

    _load (data) {
        this.name = data ? data.name : ''
        this.compositeIdentifier = data ? data.composite_identifier : ''
        this.countryCode = data ? data.country_code : ''
        this.isAuthorized = data ? data.is_authorized_distributor : true
        this.learnMoreLinks = data ? data.learn_more_links : {}
        this.contacts = data ? data.contacts.map(item => new DistributorContact(item)) : []
        this.sections = data ? data.sections.map(item => new DistributorSectionContact(item)) : []
    }

    getContact (type) {
        return (this.contacts || []).find(contact => contact.type === type)
    }

    getSectionContact (type) {
        const section = (this.sections || []).find(section => section.type === type)
        return this.getContact(section.contact)
    }

    findContactItem (item, contacts) {
        return contacts.find(contact => !!contact[item])[item]
    }
}

export class DistributorContact {
    constructor (contactData) {
        this.type = contactData ? contactData.type : ''
        this.phone = contactData ? contactData.phone : ''
        this.email = contactData ? contactData.email : ''
        this.contactForm = contactData ? contactData.contact_form : {}
    }
}

export class DistributorSectionContact {
    constructor (sectionData) {
        this.type = sectionData ? sectionData.type : ''
        this.contact = sectionData ? sectionData.contact : ''
    }
}

export class DistributorPrefix {
    constructor (prefix) {
        this.prefix = prefix

        makeObservable(this, {
            _load: action
        })

        extendObservable(this, {
            distributor: null,
            defaultLanguage: null
        })
    }

    _load (data) {
        let distributor = data ? data.distributor : {}
        this.distributor = new Distributor(distributor.id, distributor)
        this.defaultLanguage = data.default_language
    }

    static generateId (data) {
        return data.prefix
    }

    getLearnMoreLink () {
        return this.distributor.learnMoreLinks[this.defaultLanguage] || 'https://www.vectorworks.net/service-select'
    }
}

DistributorPrefix.repo = new Repository(DistributorPrefix, Urls.distributorPrefixList)

export class DistributorAssociation {
    constructor (data) {
        this.distributor = new Distributor(data.distributor.id, data.distributor)
        this.allowOnlyPaymentTokensForSubscriptions = data.allow_only_payment_tokens_for_subscriptions
        this.isS2SConversionAllowed = data.allow_s2s_conversion
        this.showS2SAnnouncement = data.show_s2s_announcement
        this.s2sOptInStartDate = data.s2s_opt_in_start_date
    }
}
