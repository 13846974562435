import ko from 'knockout'

ko.bindingHandlers.htmlFragmentTemplate = {
    // Add an html string as a template and apply current bindings to it.
    init: function () {
        return { controlsDescendantBindings: true }
    },
    update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        // remove old bindings from the content of the element
        // Based on KO v3.4.0 cleanNode: Clone the descendants list in case it changes during iteration
        let descendants = []
        ko.utils.arrayPushAll(descendants, element.getElementsByTagName('*'))
        for (let i = 0, j = descendants.length; i < j; i++) {
            ko.cleanNode(descendants[i])
        }

        // update the inner html, unwrap to support observables and/or normal properties
        element.innerHTML = ko.unwrap(valueAccessor())

        // apply the current binding context to the content of the element
        ko.applyBindingsToDescendants(bindingContext, element)
    }
}
