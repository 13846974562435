import axios from 'axios'
import config from '../components/config'

axios.defaults.xsrfCookieName = config.csrfCookie
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN'

const instance = axios.create({
    withCredentials: true,
    withXSRFToken: true,
    headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
    }
})

instance.AxiosError = axios.AxiosError
instance.isAxiosError = axios.isAxiosError
instance.interceptors.response.use(
    response => response,
    error => {
        if (axios.isAxiosError(error) &&
            [axios.AxiosError.ERR_NETWORK, axios.AxiosError.ECONNABORTED].includes(error.code)) {
            if (window.messageViewModel) {
                window.messageViewModel.showMessageDialog({type: 'error'})
                error.isHandled = true
            }
        }

        return Promise.reject(error)
    }
)

export default instance
