import moment from 'moment'

// list all locales that we want included from webpack
import 'moment/locale/de'
import 'moment/locale/it'
import 'moment/locale/da'
import 'moment/locale/sv'
import 'moment/locale/pl'
import 'moment/locale/fr'
import 'moment/locale/nl'
import 'moment/locale/nb'
import 'moment/locale/fi'
import 'moment/locale/es'
import 'moment/locale/pt'
import 'moment/locale/zh-cn'
import 'moment/locale/ja'

// Changes matching Django's formats
moment.updateLocale('en-gb', {
    longDateFormat: {
        ll: 'D MMM YYYY' // 5 Oct 2006
    }
})

moment.updateLocale('da', {
    // In Django b format September is sept
    monthsShort: ['jan', 'feb', 'mar', 'apr', 'maj', 'jun', 'jul', 'aug', 'sept', 'okt', 'nov', 'dec']
})

moment.updateLocale('nl', {
    // In both Django formats months are without (.)s
    monthsShort: ['jan', 'feb', 'mrt', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec']
})

moment.updateLocale('fr', {
    // In Django N format January is jan., February is fév.
    monthsShort: ['jan.', 'fév.', 'mars', 'avr.', 'mai', 'juin', 'juil.', 'août', 'sept.', 'oct.', 'nov.', 'déc.']
})

moment.updateLocale('de', {
    // In Django N format April is April, September is Sept.
    monthsShort: ['Jan.', 'Feb.', 'März', 'April', 'Mai', 'Juni', 'Juli', 'Aug.', 'Sept.', 'Okt.', 'Nov.', 'Dez.']
})

moment.updateLocale('nb', {
    // In Django N format March is mar., June is jun., July is jul., September is sept.
    monthsShort: ['jan.', 'feb.', 'mar.', 'apr.', 'mai', 'jun.', 'jul.', 'aug.', 'sept.', 'okt.', 'nov.', 'des.']
})

window.moment = moment
